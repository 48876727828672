import React, { Fragment, useEffect } from 'react';

import useToken from 'common/src/hooks/useToken';
import { addTokenToUrl } from 'common/src/utils/token';

const TablePlan = () => {
  const authToken = useToken();
  useEffect(() => {
    window.location.replace(
      addTokenToUrl({
        url: 'https://app.magictableplanner.com/dashboard',
        authToken
      })
    );
  });
  return <div></div>;
};

export default TablePlan;
